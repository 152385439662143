import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const usePageTracking = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname}`,
      title: `${window.location.pathname}`,
    });
  });
};

export default usePageTracking;
