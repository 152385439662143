import ReactGA from 'react-ga4';
import { ToastContainer } from 'react-toastify';
import usePageTracking from './hooks/usePageTracking';
import AppRoutes from './routes';
import { AppProvider } from './contexts';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  ReactGA.initialize(import.meta.env.VITE_GA_TRACKING_CODE);

  usePageTracking();

  return (
    <AppProvider>
      <AppRoutes />
      <ToastContainer />
    </AppProvider>
  );
}

export default App;
