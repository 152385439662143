/* eslint-disable react-refresh/only-export-components */
import {
  createContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import { isAuthenticated } from '../auth';

interface useContextInterface {
  authenticated: boolean;
  setAuthenticated: Dispatch<SetStateAction<boolean>>;
}

export interface AuthComponent {
  children: ReactNode;
}

const AuthContext = createContext<useContextInterface>(
  {} as useContextInterface,
);

export default function AuthProvider({ children }: AuthComponent) {
  const [authenticated, setAuthenticated] =
    useState<boolean>(isAuthenticated());

  return (
    <AuthContext.Provider value={{ authenticated, setAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => {
  const context = useContext<useContextInterface>(AuthContext);
  if (!context) throw new Error('useAuth must be used within a AuthProvider');
  const { authenticated, setAuthenticated } = context;
  return { authenticated, setAuthenticated };
};

export { AuthProvider, useAuth };
