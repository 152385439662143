/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal, ModalBody } from 'reactstrap';
import { SessionExpired } from '../../../contexts/sessionExpired';
import { ReactComponent as Alert } from '../../../assets/images/icons/Alert.svg';
import ButtonAction from '../../Buttons/ButtonAction';
import { logout } from '../../../auth';
import './styles.scss';

export interface Props {
  isOpen: boolean;
}

const ModalSessionExpired = ({ isOpen }: Props) => {
  const handleClickAccept = () => {
    SessionExpired.setIsOpen && SessionExpired.setIsOpen(false);
    logout();
  };

  return (
    <Modal
      centered
      isOpen={isOpen}
      className="modal-lg modal-session-expired"
      scrollable
    >
      <ModalBody className="container-session-expired">
        <div className="content-icon">
          <Alert />
        </div>
        <div className="content-session-expired">
          <h1 className="title-modal">Sua sessão expirou</h1>
          <p>É necessário fazer o login novamente</p>
        </div>
      </ModalBody>
      <ButtonAction
        className="solutra-btn primary-button"
        title="Entendi"
        onClick={handleClickAccept}
      />
    </Modal>
  );
};

export default ModalSessionExpired;
