import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import * as Sentry from '@sentry/react';
import { FlagProvider } from '@unleash/proxy-client-react';

import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.scss';

const config = {
  url: import.meta.env.VITE_FEATURE_FLAG_URL, // Your front-end API URL or the Unleash proxy's URL (https://<proxy-url>/proxy)
  clientKey: import.meta.env.VITE_FEATURE_FLAG_CLIENT_KEY, // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
  refreshInterval: 15, // How often (in seconds) the client should poll the proxy for updates
  appName: 'Front-Solutra-React', // The name of your application. It's only used for identifying your application
};

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <>
    <FlagProvider config={config}>
      <App />
    </FlagProvider>
  </>,
);
