import {
  AnimationControls,
  Target,
  TargetAndTransition,
  VariantLabels,
  motion,
} from 'framer-motion';
import './styles.scss';

type Props = {
  className?: string;
  initial?: boolean | Target | VariantLabels;
  animate?: AnimationControls | TargetAndTransition | VariantLabels | boolean;
  exit?: TargetAndTransition | VariantLabels;
};

const SystemName = ({ initial, animate, exit, className }: Props) => {
  return (
    <motion.h1
      id="system-name"
      className={className}
      initial={initial}
      animate={animate}
      exit={exit}
    >
      SOLUTRA
    </motion.h1>
  );
};

export default SystemName;
