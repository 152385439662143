import './styles.scss';
import { ReactComponent as Loading } from '../../assets/images/icons/Loading.svg';

export type Props = {
  color?: string;
  style?: any;
};

const Loader = ({ style }: Props) => {
  return <Loading className="loader" style={style} />;
};

export default Loader;
