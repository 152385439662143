import { motion } from 'framer-motion';
import './styles.scss';
import { Navbar, NavbarBrand } from 'reactstrap';
import SystemName from '../../components/SystemName';
import Loader from '../../components/Loader';

const LayoutSuspended = () => {
  return (
    <motion.div id="layout-autenticated-container-2">
      <Navbar expand={false} dark>
        <NavbarBrand href="/home">
          <SystemName className="header-system-name" />
        </NavbarBrand>
      </Navbar>
      <div id="layout-autenticated-content">
        <Loader color="blue" />
      </div>
    </motion.div>
  );
};

export default LayoutSuspended;
