/* eslint-disable react-refresh/only-export-components */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import ModalSessionExpired from '../components/Modals/ModalSessionExpired';

interface useContextInterface {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export interface SessionExpiredComponent {
  children: ReactNode;
}

interface SessionExpiredInterface {
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
}

const SessionExpiredContext = createContext<useContextInterface>(
  {} as useContextInterface,
);

//Objeto representa o acesso global a esse contexto
const SessionExpired: SessionExpiredInterface = {};

export default function SessionExpiredProvider({
  children,
}: SessionExpiredComponent) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  SessionExpired.setIsOpen = setIsOpen;

  return (
    <SessionExpiredContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
      <ModalSessionExpired isOpen={isOpen} />
    </SessionExpiredContext.Provider>
  );
}

export { SessionExpiredProvider, SessionExpired };
