import { AuthProvider, AuthComponent } from './auth';
import SessionExpiredProvider from './sessionExpired';

const AppProvider = ({ children }: AuthComponent) => {
  return (
    <AuthProvider>
      <SessionExpiredProvider>{children}</SessionExpiredProvider>
    </AuthProvider>
  );
};

export { AppProvider };
