/* eslint-disable @typescript-eslint/no-explicit-any */
import secureLocalStorage from 'react-secure-storage';

export interface Response {
  token: string;
  refreshToken: string;
  role: string;
}

export const SECURITY_KEY =
  import.meta.env.VITE_SECURE_LOCAL_STORAGE_HASH_KEY ?? '';

export const isAuthenticated = (): boolean => {
  const json: any = secureLocalStorage.getItem(SECURITY_KEY);
  const obj: Response = JSON.parse(json);
  return obj ? true : false;
};
export const isAuthorized = (role: string): boolean => {
  const json: any = secureLocalStorage.getItem(SECURITY_KEY);
  const obj: Response = JSON.parse(json);
  return role === obj?.role;
};

export const getUserAuth = (): Response => {
  const json: any = secureLocalStorage.getItem(SECURITY_KEY);
  const obj: Response = JSON.parse(json);
  return obj || '';
};

export const updateLocalAccessToken = (data: Response) => {
  const json: any = secureLocalStorage.getItem(SECURITY_KEY);
  const obj: Response = JSON.parse(json);
  obj.token = data.token;
  obj.refreshToken = data.refreshToken;
  secureLocalStorage.setItem(SECURITY_KEY, JSON.stringify(obj));
};

export const signIn = (data: Response) => {
  const jsonAux: any = JSON.stringify(data);
  secureLocalStorage.setItem(SECURITY_KEY, jsonAux);
};

export const logout = () => {
  secureLocalStorage.removeItem(SECURITY_KEY);
  window.location.href = '/login';
  localStorage.clear();
};

export const saveUserData = (data: any) => {
  localStorage.setItem('userData', JSON.stringify(data));
};
