import React, { useEffect } from 'react';
import { useAuth } from '../contexts/auth';
import { isAuthenticated } from '../auth';
import LayoutSuspended from '../pages/LayoutSuspended';
const AuthRoutes = React.lazy(() => import('./AuthRoutes'));
const NotAuthRoutes = React.lazy(() => import('./NotAuthRoutes'));

const AppRoutes = () => {
  const { authenticated, setAuthenticated } = useAuth();

  useEffect(() => {
    setAuthenticated(isAuthenticated());
  }, [setAuthenticated]);

  return (
    <React.Suspense fallback={<LayoutSuspended />}>
      {authenticated ? <AuthRoutes /> : <NotAuthRoutes />}
    </React.Suspense>
  );
};

export default AppRoutes;
