/* eslint-disable react-refresh/only-export-components */
import { memo } from 'react';
import { Button, ButtonProps } from 'reactstrap';
import { Link } from 'react-router-dom';
import Loader from '../../Loader';

export interface Props extends ButtonProps {
  title?: string;
  iconRight?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  iconRightSecond?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  iconLeft?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  iconLeftSecond?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  className?: string;
  loading?: boolean;
  path?: string;
}
// @deprecated use Button from components/Buttons/Button
const ButtonAction = ({
  title,
  iconRight,
  iconRightSecond,
  iconLeft,
  iconLeftSecond,
  className,
  loading,
  path,
  children,
  ...rest
}: Props) => {
  const IconRight = iconRight;
  const IconRightSecond = iconRightSecond;
  const IconLeft = iconLeft;
  const IconLeftSecond = iconLeftSecond;

  if (iconLeftSecond || iconRightSecond) {
    return (
      <>
        {path ? (
          <Link to={path}>
            <Button className={className} {...rest}>
              <span className="circle big d-none"></span>
              <span className="circle small d-none"></span>
              {!loading && IconLeftSecond && <IconLeftSecond />}
              <span>{loading ? <Loader /> : title}</span>
              {!loading && IconRightSecond && <IconRightSecond />}
            </Button>
          </Link>
        ) : (
          <Button className={className} {...rest}>
            <span className="circle big d-none"></span>
            <span className="circle small d-none"></span>
            {!loading && IconLeftSecond && <IconLeftSecond />}
            <span>{loading ? <Loader /> : title}</span>
            {!loading && IconRightSecond && <IconRightSecond />}
          </Button>
        )}
      </>
    );
  } else {
    return (
      <>
        {path ? (
          <Link to={path}>
            <Button className={className} {...rest}>
              <span className="circle big d-none"></span>
              <span className="circle small d-none"></span>
              {!loading && IconLeft && <IconLeft />}
              <span>{loading ? <Loader /> : title}</span>
              {!loading && IconRight && <IconRight />}
            </Button>
          </Link>
        ) : title ? (
          <Button className={className} {...rest}>
            <span className="circle big d-none"></span>
            <span className="circle small d-none"></span>
            {!loading && IconLeft && <IconLeft />}
            <span>{loading ? <Loader /> : title}</span>
            {!loading && IconRight && <IconRight />}
          </Button>
        ) : iconLeft || iconRight ? (
          <Button className={className} {...rest}>
            <span className="circle big d-none"></span>
            <span className="circle small d-none"></span>
            {!loading && IconLeft && <IconLeft />}
            <span>{loading && <Loader />}</span>
            {!loading && IconRight && <IconRight />}
          </Button>
        ) : (
          <Button className={className} {...rest}>
            <span className="circle big d-none"></span>
            <span className="circle small d-none"></span>
            {children}
          </Button>
        )}
      </>
    );
  }
};

export default memo(ButtonAction);
